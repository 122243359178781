<template>
  <div class="readme-article">
    <h1 id="高级功能">
      五、高级功能
    </h1>
    <h2 id="学管带管人数">1.学管带管人数</h2>
    <p>查看学管带管人数列表。</p>
    <h2 id="历史剩余课费">2.历史剩余课费</h2>
    <p>查看有剩余课费得学员列表，包括学生得缴费，课耗等信息。</p>
    <p>
      <img src="@/assets/img/student/5-1.png">
    </p>
    <h2 id="修改日志">3.修改日志</h2>
    <p>查看学生操作日志，可查看日志详情。</p>
    <p>
      <img src="@/assets/img/student/5-2.png">
    </p>
    <h2 id="学生升级">4.学生升级</h2>
    <p>批量升级学生年级，例如：一年级升级到二年级，初三升级到高一，高三升级到毕业。</p>
    <p>
      <img src="@/assets/img/student/5-3.png">
    </p>
    <h2 id="导入学生资料">5.导入学生资料</h2>
    <p>系统支持一键导入学生资料，根据提供的示例文件模板完善表格导入即可。</p>
    <p>
      <img src="@/assets/img/student/5-4.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student5-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>